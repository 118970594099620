.block-locations {
  display: block;
  &.location-filter-dropdown {
    .region-dropdown-container {
      display: flex;
      justify-content: center;
    }
    .list-states {
      display: none;
    }
  }
  .block-container {
    display: flex;
    flex-direction: column;
    .region-dropdown-container {
      display: none;
    }
    .list-states {
      display: flex;
      justify-content: space-evenly;
      width: 80%;
      margin: 50px auto;
      text-align: center;
      @include screen-md-max {
        margin: 0 auto 30px;
        width: 100%;
        flex-wrap: wrap;
      }
      .state {
        align-self: flex-start;
        margin: 0;
        padding-bottom: 5px;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;
        font-size: var(--block-locations--state-font-size-desktop);
        color: var(--block-locations--state-colour);
        border-bottom: 5px solid transparent;
        transition: color $transition-03s, borderBottom $transition-03s;
        @include screen-md-max {
          flex-basis: 33.333%;
          margin-bottom: 6px;
          font-size: var(--block-locations--state-font-size-mobile);
        }
      }
      .state.active {
        color: var(--block-locations--state-active-colour);
        text-decoration: underline;
        text-decoration-color: var(--block-locations--state-active-text-decoration-colour);
        text-decoration-thickness: 5px;
        text-underline-offset: 8px;
      }
    }
    .list-venues {
      border-top: 1px solid var(--block-locations--border);
      .venue {
        display: none;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--block-locations--border);
        padding: 20px 0;
        animation: fade-out $transition-03s;
        .venue-details {
          display: flex;
          width: 100%;
          a {
            text-decoration: none;
          }
          @include screen-md-max {
            flex-direction: column;
          }
          .section-1 {
            display: flex;
            align-items: center;
            @include screen-md-max {
              margin-bottom: 0;
            }
            .venue-suburb {
              text-transform: var(--block-locations--venue-suburb-text-transform);
              margin: 0;
              margin-right: 20px;
              color: var(--block-locations--venue-suburb);
              font-weight: 600;
            }
          }
          .section-2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
  
            
            &.with-activities{
              @include screen-md-max{
                flex-direction: column;
                align-items: start;
              }
  
              .venue-info{
                display:flex;
                justify-content: space-between;
                align-items:center;
              
                @include screen-md-max{
                  width:100%;
                }
    
                .activities{
                  margin-right:20px;
  
                  .activity-icon{                  
                    display:inline;
                    position:relative;
                    cursor: pointer;
                    
                    .tooltip{
                      visibility: hidden;
                      position:absolute;
                      background-color: var(--block-locations--tooltip-bg-color);
                      color: var(--block-locations--tooltip-text-color);
                      text-align:center;
                      padding: 5px 20px;           
                      top:100%;
                      left: 50%;     
                      width:120px;
                      margin-left: -60px;    
                      z-index: 100;
                      margin-top: 10px;
                    }
  
                    
                    &:hover .tooltip{
                      visibility: visible;
                    }
  
                  }
                }              
              }
            }
  
            .venue-address {            
              @include screen-xl-max {
                max-width: 170px;
              }
              @include screen-md-max {
                margin-top: 10px;
              }
            }
  
            
           
            .book-button-desktop {
              display: block;
              @include screen-md-max {
                display: none;
              }
            }
            .book-button-mobile {
              display: none;
              @include screen-md-max {
                display: block;
              }
            }
          }
        }
      }
      .venue.show {
        display: flex;
        animation: fade-in $transition-03s;
      }
      .venue:last-child {
        border-bottom: 1px solid var(--block-locations--border);
      }
    }
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}